import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

interface IImagePreviewProps {
  image: string;
  onClose: Function;
}

const ImagePreview = (props: IImagePreviewProps) => {

  const ref : MutableRefObject<HTMLDivElement> = useRef<any>();

  const onClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const inPreview = target?.closest('.image');
    if(!inPreview) {
      props.onClose();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', onClick);
    });
    return () => {
      window.removeEventListener('click', onClick);
    }
  }, []);

  return (
    <div className="relative z-[100] image-preview-container" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed flex justify-center top-8 bottom-0 z-[100] w-screen overflow-y-scroll">
        <div className='px-20'>
          <img className='image' src={props.image} />
        </div>
      </div>
    </div>
  );
}

export default ImagePreview;