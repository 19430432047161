import { Route, Routes, Link } from 'react-router-dom';
import useGetSettings from '../Hooks/useGetSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faFacebookSquare, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';

function Footer() {
    const { data: settings } = useGetSettings();
    const [ email, setEmail ] = useState('');
    const [ hidden, setHidden ] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleNewsletterSubmitButtonClick = async () => {
      try {
        setIsSubmitted(true);

        const formData = new URLSearchParams();
        formData.append('EMAIL', email);
        formData.append('b_744e18b5b2156404b57a7c159_53376f0bc9', hidden);
  
        // Fetch call with form data
        const response = await fetch('https://browninggenealogy.us5.list-manage.com/subscribe/post?u=744e18b5b2156404b57a7c159&id=53376f0bc9', {
          mode: 'no-cors',
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData.toString(), // Send as form-urlencoded
        });
      } catch (error) {
        setIsSubmitted(true);
        console.error('Error Signing Up');
      }
    };
    return (
        <div className='flex flex-col place-items-center py-12 gap-y-4 bg-[#056839] lg:px-40 w-full text-xs lg:text-lg text-center'>
          <div className='flex gap-4'>
            <Link to='/' className='flex flex-col place-items-center'>
              { settings && settings.logo &&
                <img className='flex w-44' src={settings.logo} />
              }
            </Link>
            <div className='flex flex-col gap-4'>
              <span className='text-white font-bold text-left'>Sign Up for our Newsletter!</span>
              {!isSubmitted ? (
              <div className='flex gap-2 text-white'>
                <input
                  placeholder='Email Address'
                  className='border-b border-b-white bg-transparent outline-none'
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
                <input
                  placeholder=''
                  className='hidden'
                  value={hidden}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHidden(e.target.value)}
                />
                <button
                  type='button'
                  className='border-2 border-white rounded px-2'
                  onClick={handleNewsletterSubmitButtonClick}
                >SIGN UP</button>
              </div>
               ) : (
                <div id="responseContainer">
                  <p>Thank you for joining!</p>
                </div>
              )}
              <span className='font-bold flex flex-col text-white self-start text-left mt-2 text-base'>
                <span>Find us on</span>
                <span>Facebook & YouTube!</span>
              </span>
              <div className='flex gap-2 text-white text-6xl'>
                { settings &&
                  <>
                    <Link to={settings.facebook_url}>
                      <FontAwesomeIcon icon={faFacebookSquare} />
                    </Link>
                    <Link to={settings.youtube_url}>
                      <FontAwesomeIcon icon={faYoutube} />
                    </Link>
                  </>
                }
              </div>
            </div>
          </div>
          <div className='flex gap-x-8 text-white text-sm lg:text-lg'>
            <Link to='/about'>About</Link>
            <Link to='/contact'>Contact</Link>
          </div>
          <div className='text-primary-light flex flex-col gap-y-4'>
            <span className='text-sm lg:text-base'>&copy;2024 Browning Genealogy & Local History</span>
            <span className='text-xs lg:text-sm flex flex-col'>
                <span>This website or any portion thereof may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for</span>
                <span>any commercial purpose without the written consent of the Browning Family Genealogy & Local History Foundation, Inc.</span>
            </span>
          </div>
        </div>
    )
}
export default Footer;