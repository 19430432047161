import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import API from '../Utility/API';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Entry } from '../Interfaces/Entry';
import FormEntryField from './FormEntryField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { EntryCorrectionValues } from '../Interfaces/EntryCorrectionValues';
import { EntryCorrection as EntryCorrectionInterface } from '../Interfaces/EntryCorrection';
import { EntryValue } from '../Interfaces/EntryValue';
import { Link } from 'react-router-dom';
import Util from '../Utility/Util';
import { Notification, NotificationTypes } from '../Interfaces/Notification';
import useWindowSize from '../Hooks/useWindowSize';
import DropdownButton from './DropdownButton';
import { useAppStore } from '../Hooks/useAppStore';
import { EntryField } from '../Interfaces/EntryField';
import APIError from '../Interfaces/APIError';

interface IEntryCorrectionProps {
}

const EntryCorrection = (props: IEntryCorrectionProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams<{ entryId: string }>();
  const entryId: number = Number(params.entryId);
  const [ width ] = useWindowSize();
  const [ disableButtons, setDisableButtons ] = useState<{[key: number]: boolean}>({});
  const [ corrections, setCorrections ] = useState<EntryCorrectionValues[]>([]);
  const [ selectedSuggestion, setSelectedSuggestion ] = useState<{[key: number]: {id: number, value: string}}>({});
  const notify = useAppStore((state) => state.notify);
  const { data, isFetching } = useQuery({
    queryKey: ['correctionsByEntryId', entryId],
    queryFn: async () => {
      return await API.getCorrectionsByEntryId(entryId);
    }
  });

  const { mutate: reject } = useMutation({
    mutationFn: API.rejectEntryCorrection,
    onSuccess: (data) => {
      queryClient.setQueryData(['correctionsByEntryId', entryId], data);
      let message: Notification = {
        message: "Successfully rejected changes.",
        time: 4000
      };
      Util.scrollToTop('smooth');
      if(!data.corrections.length) {
        message.altMessage = "Entry Correction is now closed."
        notify(message);
        navigate(`/admin/corrections`);
      }
      notify(message);
      setDisableButtons({});
    },
    onError: (error: APIError) => {
      setDisableButtons({});
    }
  });
  
  const { mutate: accept } = useMutation({
    mutationFn: API.acceptEntryCorrection,
    onSuccess: (data) => {
      queryClient.setQueryData(['correctionsByEntryId', entryId], data);
      let message: Notification = {
        message: "Successfully accepted changes.",
        time: 4000
      };
      Util.scrollToTop('smooth');
      if(!data.corrections.length) {
        message.altMessage = "Entry Correction is now closed."
        notify(message);
        navigate(`/admin/corrections`);
      }
      notify(message);
      setDisableButtons({});
    },
    onError: (error: APIError) => {
      setDisableButtons({});
    }
  });

  useEffect(() => {
    if(data) {
      buildCorrections();
    }
  }, [data]);

  useEffect(() => {
    if(data && !isFetching && !corrections.length) {
      notify({
        message: "Entry correction is closed.",
        altMessage: "No corrections found.",
        time: 4000
      });
      navigate(`/admin/corrections`);
    }
  }, [corrections])

  useEffect(() => {
    if(Util.isMobile()) {
      notify({
        message: "Unable to make corrections on mobile.",
        type: NotificationTypes.Warning
      });
      navigate('/');
    }
  }, [width]);

  const buildCorrections = () => {
    let tempCorrections : EntryCorrectionValues[] = [];
    data.corrections.forEach((correction: EntryCorrectionInterface) => {
      correction.correctionValues.forEach((value: EntryCorrectionValues) => {
        tempCorrections.push(value);
      });
    });

    setCorrections([...tempCorrections])
  }

  const getField = (fieldId: number) => {
    let entry = data.entry;
    return entry.database?.entryFields.find((i: {id: number}) => i.id === fieldId)?.name;
  }

  const getValueByFieldId = (fieldId: number) => {
    return data.entry.values.find((i: {entry_field_id: number}) => i.entry_field_id === fieldId)?.value;
  }

  const getValue = (value: EntryValue) => {
    let retVal = value.value;
    if(value.field_type_name === 'Date' && retVal) {
      retVal = new Date(retVal).toISOString().split('T')[0];
    }

    return retVal ?? '';
  }

  const rejectChanges = async (fieldId: number) => {
    disableButtons[fieldId] = true;
    setDisableButtons({...disableButtons});
    reject({entryId, fieldId});
  }

  const acceptChanges = async (fieldId: number) => {
    disableButtons[fieldId] = true;
    setDisableButtons({...disableButtons});
    let suggestedChange = selectedSuggestion[fieldId];
    if(suggestedChange === undefined) {
      let suggestions = corrections.filter((i: {entry_field_id: number}) => i.entry_field_id === fieldId);
      if(suggestions.length === 1) {
        suggestedChange = {id: suggestions[0].id, value: suggestions[0].new_entry_value};
      } else {
        Util.scrollToTop('smooth');
        notify({
          message: "Unable to accept changes",
          altMessage: "Please select the correct value.",
          type: NotificationTypes.Error
        });
        return;
      }
    }

    accept({entryId: data.entry.id, correctionId: suggestedChange.id});
  }

  const acceptRejectButtons = (fieldId: number) => {
    return (
      <div className='flex gap-x-2 lg:gap-x-8 w-full justify-center'>
        <button
          type='button'
          className='px-2 py-1 bg-green-400 border border-secondary rounded-lg text-sm'
          onClick={async () => {
            acceptChanges(fieldId);
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </button>
        <button
          type='button'
          className='px-2 py-1 bg-red-500 border border-secondary rounded-lg text-sm'
          onClick={async () => {
            rejectChanges(fieldId)
          }}
        >
          <FontAwesomeIcon icon={faX} />
        </button>
      </div>
    );
  }

  const handleSuggestions = (fieldId: number) => {
    let entryField = data.entry.database.entryFields.find((i: {id: number}) => i.id === fieldId);
    let suggestions = corrections.filter((i: {entry_field_id: number}) => i.entry_field_id === fieldId);
    if(suggestions.length && suggestions.length > 1) {
      return (
        <>
          <td>
            <div className='flex justify-center place-items-center w-full'>
              <DropdownButton
                className='flex justify-between h-11 place-items-center p-2 border rounded-lg gap-x-2 w-56 border-gray-700 rounded shadow-lg outline-0'
                parentClass='relative'
                childClass='p-4 cursor-pointer hover:bg-gray-300 bg-gray-200 min-h-14'
                // buttonText={ selectedSuggestion[fieldId]?.value ?? 'Are any of these changes valid?' }
                buttonText={ selectedSuggestion[fieldId] ? selectedSuggestion[fieldId].value ?? '\u00A0' : 'Are any of these changes valid?'}
                onChange={(key: string) => {
                  let suggestion = suggestions.find((i: {id: number}) => i.id.toString() === key);
                  if(suggestion) {
                    selectedSuggestion[suggestion.entry_field_id] = {
                      id: suggestion.id,
                      value: suggestion.new_entry_value
                    }
                    setSelectedSuggestion({...selectedSuggestion})
                  }
                }}
                dropdownItems={
                  suggestions.reduce((obj: {[key: number]: string}, item) => (
                    obj[item.id] = item.new_entry_value, obj
                  ), {})
                }
              />
            </div>
          </td>
          <td>
            {acceptRejectButtons(fieldId)}
          </td>
        </>
      );
    } else if(suggestions.length) {
      const suggestion = suggestions[0];
      if(entryField.field_type_name === 'Date' && suggestion.new_entry_value) {
        suggestion.new_entry_value = new Date(suggestion.new_entry_value).toISOString().split('T')[0];
      }
      // selectedSuggestion[fieldId] = suggestion.new_entry_value;
      // setSelectedSuggestion({...selectedSuggestion});
      return (
        <>
          <td className='px-4 lg:px-8'>
            <div className='flex p-2 bg-white border border-gray-700 rounded-lg h-11'>
              {suggestion.new_entry_value}
            </div>
          </td>
          <td>
            {acceptRejectButtons(fieldId)}
          </td>
        </>
      );
    }

    return (
      <>
        <td>No Suggested Changes</td>
        <td></td>
      </>
    );
  }

  return (
    <div className='my-8 px-8 lg:px-80 cursor-default'>
      <div className='flex relative lg:mb-8 justify-center'>
        <div className='flex gap-x-2 place-items-center absolute left-0'>
          <FontAwesomeIcon icon={faArrowLeft} />
          <Link to='/admin/corrections'>Back to Corrections</Link>
        </div>
        <h1 className='flex font-bold mt-8 lg:mt-0'>Entry Corrections</h1>
      </div>
      <div className='flex flex-col gap-4 justify-center w-full text-xs lg:text-base'>
        <table className='border-separate border-spacing-y-0'>
          <thead className='font-bold text-center mb-4'>
            <tr>
              <th>Field Name</th>
              <th>Current Value</th>
              <th>
                <FontAwesomeIcon icon={faArrowRight} className='self-center w-full'/>
              </th>
              <th>Suggested Change(s)</th>
              <th>Accept/Reject Change(s)</th>
            </tr>
          </thead>
          <tbody>
            {data && data.entry.database.entryFields.map((entryField: EntryField, key: number) => (
              <tr key={key} className='text-center odd:bg-primary-light text-secondary h-12'>
                <td className='text-left pl-4'>{entryField.name}</td>
                <td className='font-bold'>
                  {getValueByFieldId(entryField.id)}
                </td>
                <td>
                  <FontAwesomeIcon icon={faArrowRight} className='self-center text-center w-full'/>
                </td>
                { !disableButtons[entryField.id] &&
                  handleSuggestions(entryField.id)
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EntryCorrection;