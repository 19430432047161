import React, { useState, useEffect, MutableRefObject, useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import API from '../Utility/API';
import { DatabaseOption } from '../Interfaces/DatabaseOption';
import { EntryField } from '../Interfaces/EntryField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import DropdownButton from './DropdownButton';
import SearchResultSet from './SearchResultSet';
import FormEntryField from './FormEntryField';

import { SearchApiResponse } from '../Interfaces/SearchApiResponse';
import { EntryType } from '../Interfaces/EntryType';
import useGetDatabaseMetadata from '../Hooks/useGetDatabaseMetadata';
import useGetEntryMetadata from '../Hooks/useGetEntryMetadata';
import useSearchMetadata from '../Hooks/useSearchMetadata';
import EntryMetadataType from '../Interfaces/EntryMetadataType';
import useGetDatabaseGroupings from '../Hooks/useGetDatabaseGroupings';
import AdvancedDropdown from './AdvancedDropdown';
import { Database } from '../Interfaces/Database';
import DatabaseGrouping from '../Interfaces/DatabaseGrouping';
import useGetDatabaseGroupingMetadata from '../Hooks/useGetDatabaseGroupingMetadata';
import useGetMetadata from '../Hooks/useGetMetadata';

interface IEntrySearchValues {
  [key: string]: string
};

interface IAdvancedSearchProps {

}

const dropdownOverrides = {
  Obituaries: "Obituary Databases"
};

function AdvancedSearch(props: IAdvancedSearchProps) {
  const searchTypeClasses = {
    active: 'p-2 bg-secondary text-white',
    default: 'p-2 bg-gray-300'
  };
  const queryClient = useQueryClient();
  const resultsRef: MutableRefObject<HTMLDivElement> = useRef<any>();
  // State to store the dropdown options
  const [database, setDatabase] = useState<Database>();
  const [ searchType, setSearchType ] = useState(0);
  const [ databaseId, setDatabaseId ] = useState(0);
  const [ databaseGroup, setDatabaseGroup ] = useState<DatabaseGrouping>();
  const { data: databaseGroupings } = useGetDatabaseGroupings();
  // const { data: metadata } = useGetDatabaseMetadata(databaseId ?? 0);
  const [ sortedMetadata, setSortedMetadata ] = useState<EntryMetadataType[]>();
  const { data: metadata } = useGetMetadata({ databaseId: databaseId, groupingId: databaseGroup?.id});
  // const { data: metadata } = useGetDatabaseGroupingMetadata(databaseGroup?.id);
  const { data: entryFieldsForDatabase, isLoading } = useQuery({
    queryKey: ['entry-fields', databaseId, databaseGroup],
    queryFn: () => API.getEntryFields(databaseId, databaseGroup?.id),
    enabled: !!(databaseId || databaseGroup?.id)
  });
  const [entrySearchValues, setEntrySearchValues] = useState<IEntrySearchValues>({});
  const [searchValues, setSearchValues] = useState<IEntrySearchValues>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [ metadataSearchTerm, setMetadataSearchTerm ] = useState('');
  const [ doMetadataSearch, setDoMetadataSearch ] = useState(false);
  const [resultsPerPage, setResultsPerPage] = useState(Number(localStorage.getItem(`paginate-advanced-search`) ?? 9));
  const { data, isLoading: isLoadingData, refetch: searchData } = useQuery<SearchApiResponse>({
    queryKey: ['advanced-search', searchValues, currentPage, resultsPerPage],
    queryFn: async () => {
      return await API.advancedSearch({
        searchValues,
        database: databaseId ?? 0,
        currentPage,
        resultsPerPage,
        databaseGroup: databaseGroup?.id ?? 0
      });
    },
    enabled: Object.keys(searchValues).length > 0
  });
  const { data: metadataData, isLoading: isLoadingMetadata, refetch: searchMetadata } = useSearchMetadata({
    searchTerm: metadataSearchTerm,
    metadataTypeId: searchType,
    page: currentPage,
    doSearch: doMetadataSearch,
    resultsPerPage
  }, () => {
    setDoMetadataSearch(false);
  });
  const [groupedData, setGroupedData] = useState<any[]>([]);
  const [fieldMap, setFieldMap] = useState<{[key: number]: string}>({});
  const [recordTypeMap, setRecordTypeMap] = useState<{[key: number]: string}>({});
  const [showInputFields, setShowInputFields] = useState(true);

  const handleSearch = () => {
    if(metadataSearchTerm) {
      setDoMetadataSearch(true);
    } else {
      setCurrentPage(1);
      setSearchValues({...entrySearchValues});
    }
  };

  // Creates mapping of entry field ids => entry field name
  useEffect(() => {
    mapFields();
    setShowInputFields(false);
  }, [data]);

  useEffect(() => {
    const fieldSearch : EntryMetadataType = {
      id: 0,
      type: 'Field Search',
      name: 'Field Search',
      sort: 1,
      includeSearch: true
    };
    if(metadata) {
      let tempEntryMetadata = [
        ...metadata,
        fieldSearch
      ];
      let sorted : EntryMetadataType[] = tempEntryMetadata.sort((a, b) => {
        if(a.sort === b.sort) return 0;

        if(a.sort === null) return 1;

        if(b.sort === null) return -1;

        return a.sort < b.sort ? -1 : 1;
      });
      setSortedMetadata(sorted);
      setSearchType(sorted[0].id);
    }
  }, [metadata]);

  useEffect(() => {
    groupEntryData();
  }, [entryFieldsForDatabase]);

  function mapFields() {
    if(!database) return;
    let fieldMapping: any = {};
    entryFieldsForDatabase?.forEach((entryField: EntryField) => {
      if(!fieldMapping[databaseId]) {
        fieldMapping[databaseId] = {};
      }
      fieldMapping[databaseId][entryField.id] = entryField.name;
    });

    let recordMapping: any = {};
    data?.entryTypes.forEach((type: EntryType) => {
      recordMapping[type.id] = type.name;
    });

    setFieldMap(fieldMapping);
    setRecordTypeMap(recordMapping);
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    let id = target.id;
    id = id.substring('entry_field_'.length);
    if(value) {
      entrySearchValues[id] = value;
    } else if(entrySearchValues[id]) {
      delete(entrySearchValues[id]);
    }

    setMetadataSearchTerm('');
    setEntrySearchValues({...entrySearchValues});
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'start'|'end') => {
    const target = event.target;
    const value = target.value;
    let id = target.id;
    id = id.substring('entry_field_'.length);
    if(value) {
      entrySearchValues[id] = value;
    } else if(entrySearchValues[id]) {
      delete(entrySearchValues[id]);
    }

    setMetadataSearchTerm('');
    setEntrySearchValues({...entrySearchValues});
  }

  const handleMetadataSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMetadataSearchTerm(e.target.value);
  }

  const handlePageChange = async (page: number, resultsPerPage: number) => {
    setCurrentPage(page);
    setResultsPerPage(resultsPerPage);
    if(metadata) setDoMetadataSearch(true);
  }

  function updateDatabase(database?: Database, grouping?: DatabaseGrouping) {
    if(database) {
      setDatabaseId(database.id);
      setDatabase(database);
      setDatabaseGroup(undefined);
    } else if(grouping) {
      setDatabase(undefined);
      setDatabaseId(0);
      setDatabaseGroup(grouping);
    }
    setGroupedData([]);
    setEntrySearchValues({});
    setSearchType(0);
  }

  function groupEntryData() {
    // Group Entry Field data into 2s
    let tempData = [];
    let hasData = true;
    let i = 0;
    while(hasData && entryFieldsForDatabase && entryFieldsForDatabase.length) {
      let field1 = entryFieldsForDatabase[i];
      let field2 = entryFieldsForDatabase[i + 1];
      if(field1 || field2) {
        tempData.push([field1, field2]);
      } else {
        hasData = false;
      }
      i = i + 2;
    }

    setGroupedData(tempData);
  }

  const handleSearchTypeClass = (typeId: number) => {
    if(typeId === searchType) {
      return searchTypeClasses.active;
    }

    return searchTypeClasses.default;
  }

  const onEnter = (e: React.KeyboardEvent) => {
    if(e.key === 'Enter') {
      handleSearch();
    }
  }

  return (
    <div className='flex flex-col place-items-center w-full'>
      <div className='flex flex-col justify-center w-full xl:w-1/2 px-4 lg:px-0 text-sm lg:text-lg'>
        { (!data || showInputFields) && !(isLoadingData || isLoadingMetadata) &&
          <form className='w-full mb-8'>
            <div className='flex flex-col'>
              <label className='font-bold col-span-2'>Database</label>
              { databaseGroupings &&
                <AdvancedDropdown
                  groupings={databaseGroupings}
                  onChange={updateDatabase}
                  selected={database ?? databaseGroup}
                  overrides={dropdownOverrides}
                />
              }
            </div>
            { sortedMetadata && sortedMetadata.length > 0 &&
              <div className='flex mt-4 justify-center'>
                <div className='flex bg-gray-300 p-1 text-sm lg:text-lg divide-x divide-secondary'>
                  { sortedMetadata.map((mData: EntryMetadataType) => (
                    mData.includeSearch &&
                    <button key={mData.type} type='button' className={handleSearchTypeClass(mData.id)} onClick={() => { setSearchType(mData.id) }}>{mData.name}</button>
                  ))}
                </div>
              </div>
            }
            { databaseGroup && databaseGroup.name === 'Obituaries' &&
              <h1 className='text-center text-sm my-4'>Our Collection of notecards entered prior to 1999 can only be searched by name/date of death/age.</h1>
            }
            { searchType === 0 &&
              <div className=''>
                {groupedData && groupedData.map((group: any, key: number) => (
                  <div key={key} className='grid grid-cols-2 gap-x-4 lg:gap-x-8 my-2 lg:my-4'>
                    <label htmlFor={'entry_field_' + group[0].id} className='font-bold'>{group[0].name}</label>
                    <label htmlFor={'entry_field_' + group[1]?.id} className='font-bold'>{group[1]?.name}</label>
                    { group[0].field_type_id !== 3 &&
                      <FormEntryField
                        id={'entry_field_' + group[0].id}
                        className='p-2 border rounded-lg lg:p-4 outline-none shadow-lg focus:shadow-2xl ring-primary focus:ring-2'
                        placeholder={group[0].name}
                        onChange={handleInputChange}
                        value={entrySearchValues[group[0].id] ?? ''}
                        fieldType={group[0].field_type_id}
                        onKeyDown={onEnter}
                        entryFieldId={group[0].id}
                      />
                    }
                    { group[0].field_type_id === 3 &&
                      <div className='grid grid-rows-2 lg:grid-cols-2 lg:grid-rows-none gap-2'>
                        <FormEntryField
                          id={'entry_field_' + group[0].id + '_start'}
                          className='p-2 border rounded-lg lg:p-4 outline-none shadow-lg focus:shadow-2xl ring-primary focus:ring-2'
                          placeholder={group[0].name}
                          onChange={handleDateChange}
                          value={entrySearchValues[group[0].id + '_start'] ?? ''}
                          fieldType={group[0].field_type_id}
                          onKeyDown={onEnter}
                          useDateRange={true}
                          entryFieldId={group[0].id}
                        />
                        <FormEntryField
                          id={'entry_field_' + group[0].id + '_end'}
                          className='p-2 border rounded-lg lg:p-4 outline-none shadow-lg focus:shadow-2xl ring-primary focus:ring-2'
                          placeholder={group[0].name}
                          onChange={handleDateChange}
                          value={entrySearchValues[group[0].id + '_end'] ?? ''}
                          fieldType={group[0].field_type_id}
                          onKeyDown={onEnter}
                          useDateRange={true}
                          dateMin={entrySearchValues[group[0].id + '_start'] ?? ''}
                          entryFieldId={group[0].id}
                        />
                      </div>
                    }
                    {group && group[1] && Object.keys(group[1]).length > 0 &&
                      <>
                        { group[1].field_type_id !== 3 &&
                          <FormEntryField
                            id={'entry_field_' + group[1].id}
                            className='p-2 border rounded-lg lg:p-4 outline-none shadow-lg focus:shadow-2xl ring-primary focus:ring-2'
                            placeholder={group[1].name}
                            onChange={handleInputChange}
                            value={entrySearchValues[group[1].id] ?? ''}
                            fieldType={group[1].field_type_id}
                            onKeyDown={onEnter}
                            entryFieldId={group[1].id}
                          />
                        }
                        { group[1].field_type_id === 3 &&
                          <div className='grid grid-rows-2 lg:grid-cols-2 lg:grid-rows-none gap-2'>
                            <FormEntryField
                              id={'entry_field_' + group[1].id + '_start'}
                              className='p-2 border rounded-lg lg:p-4 outline-none shadow-lg focus:shadow-2xl ring-primary focus:ring-2'
                              placeholder={group[1].name}
                              onChange={handleDateChange}
                              value={entrySearchValues[group[1].id + '_start'] ?? ''}
                              fieldType={group[1].field_type_id}
                              onKeyDown={onEnter}
                              useDateRange={true}
                              entryFieldId={group[1].id}
                            />
                            <FormEntryField
                              id={'entry_field_' + group[1].id + '_end'}
                              className='p-2 border rounded-lg lg:p-4 outline-none shadow-lg focus:shadow-2xl ring-primary focus:ring-2'
                              placeholder={group[1].name}
                              onChange={handleDateChange}
                              value={entrySearchValues[group[1].id + '_end'] ?? ''}
                              fieldType={group[1].field_type_id}
                              onKeyDown={onEnter}
                              useDateRange={true}
                              dateMin={entrySearchValues[group[1].id + '_start'] ?? ''}
                              entryFieldId={group[1].id}
                            />
                          </div>
                        }
                      </>
                    }
                  </div>
                ))}
              </div>
            }
            { searchType !== 0 && metadata && metadata.length > 0 &&
              <div className='flex flex-col pt-4'>
                <span className='flex w-full justify-center'>{metadata.find((i : { id: number }) => i.id === searchType).name}</span>
                <input onKeyDown={onEnter} placeholder='Search' className='p-4 border border-gray-200 rounded-3xl w-full text-sm lg:text-lg shadow-lg outline-none ring-primary focus:ring-2' value={metadataSearchTerm} onChange={handleMetadataSearchChange}/>
              </div>
            }
            { entryFieldsForDatabase !== undefined && entryFieldsForDatabase.length > 0 &&
              <button type='button' className='button-search mt-4' onClick={handleSearch}>SEARCH</button>
            }
            { entryFieldsForDatabase === null && !isLoading && database &&
              <span className='flex justify-center mt-4'>No Fields Found.</span>
            }
          </form>
        }
      </div>
      {data && !data.entries.length &&
        <span className='font-bold mb-8'>No Records Found. Make sure you try all variations of spelling for a name. Ex: Thomas - Tom - Tomas etc.</span>
      }
      {(isLoadingData || isLoadingMetadata) &&
        <span>Loading...</span>
      }
      {data && !metadataData &&
        <div ref={resultsRef} className='flex flex-col w-full'>
          { !showInputFields &&
            <div className='grid grid-cols-4 gap-4 mb-8'>
              <button
                type='button'
                className='button-search col-start-2'
                onClick={() => {setShowInputFields(true)}}
              >Refine Search</button>
              <button
                type='button'
                className='button-search'
                onClick={() => {
                  setEntrySearchValues({});
                  setShowInputFields(true);
                }}
              >Clear Search</button>
            </div>
          }
          <SearchResultSet
            refetchData={searchData}
            recordCount={data.entries.length}
            fieldMapping={fieldMap}
            dataset={data}
            recordTypeMap={recordTypeMap} 
            totalCount={data.totalCount ?? 0}
            paginateKey={'advanced-search'}
            onChange={handlePageChange}
            currentPage={currentPage}
            scrollToRef={resultsRef}
          />
        </div>
      }
      {metadataData &&
        <div ref={resultsRef} className='flex flex-col w-full'>
          <SearchResultSet
            refetchData={searchMetadata}
            recordCount={metadataData.entries.length}
            fieldMapping={fieldMap}
            dataset={metadataData}
            recordTypeMap={recordTypeMap} 
            totalCount={metadataData.totalCount ?? 0}
            paginateKey={'advanced-search'}
            onChange={handlePageChange}
            currentPage={currentPage}
            scrollToRef={resultsRef}
          />
        </div>
      }
    </div>
  );
}

export default AdvancedSearch;